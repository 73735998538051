import {autorun, action, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';
import {AuthenticationDetails, CognitoUserPool, CognitoUser} from 'amazon-cognito-identity-js';
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import amazonConfig from '../../configs/amazonConfig';
import {defaultCache} from '../../services/core/axios-service';
import {findMatchingCategories} from '../../services/helpers/eventHelper';
import {LocalStore} from '../../services/core/storage-service';
import TimeService from '../../services/core/time-service';
import CrispService from '../../services/core/crisp-service';
import ServiceCacheService from '../../services/core/cache-service';
import {CommandExecutor} from '../../services/core/command-executor';
import UserApi from '../../services/api/userApi';
import {AppActionType, sendMessageToApp} from '../../utils/appUtils';

export class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.serviceCache = ServiceCacheService;
    this.commandExecutor = new CommandExecutor();

    extendObservable(this, {
      user: {},
      isProfileLoading: true,
      isLoading: false,
      isUserLogged: false,
      error: '',
      errorInstance: {},
      resetPassword: false,
      authToken: '',
      connectedEmails: [],
      isSendToAddNewEmail: false,
      newEmail: null,
      myParticipantBooth: null,
    });

    autorun(() => {
      if (!isEmpty(LocalStore.getObject('dealroom_data'))) {
        runInAction(() => {
          this.getDetails();
        });
      } else {
        runInAction(() => {
          this.isUserLogged = false;
          this.isProfileLoading = false;
        });
      }
    });
  }

  @action
  getDetails(update) {
    this.isLoading = !update;
    return UserApi.getProfileDetails()
      .then(resp => {
        const {data} = resp;
        TimeService.setNow(data.now);
        Sentry.setUser({id: data.id, username: `${data.name} ${data.surname}`});
        CrispService.setUsername(data.name, data.surname, data.username);

        this.setUserDetails(data);

        if (update) {
          return null;
        }

        return this.authFbUser().then(() =>
          firebase
            .database()
            .ref(`ui/${data.id}`)
            .once('value')
            .then(snapshot => {
              const uiSettings = snapshot?.val();
              runInAction(() => {
                this.user = data;
                this.isLoading = false;
                this.isUserLogged = true;
                this.user.phone = data.channels.sms;
                this.isProfileLoading = false;
                this.user.loaded = true;

                this.rootStore.eventStore.getEvents();
                data.uiSettings = uiSettings == null ? {} : uiSettings;
                this.user.uiSettings = uiSettings;
                window.DR?.dispatchUserChange(this.user.id, true);
              });
              return data;
            }),
        );
      })
      .catch(error => {
        runInAction(() => {
          this.isLoading = false;
          this.isUserLogged = false;
          this.isProfileLoading = false;
          this.error = error.message;
        });
      });
  }

  @action
  setUserDetails(data) {
    runInAction(() => {
      this.user = Object.assign(this.user, data);
      this.user.phone = data.channels.sms;
    });
  }

  lastActiveFirebaseKey;

  @action.bound
  login(provider, params) {
    this.serviceCache.destroyAll();
    // eslint-disable-next-line prefer-const
    let {username, password} = params;
    let authPromise = '';
    this.isLoading = true;

    if (provider === 'cognito') {
      if (username != null) {
        username = username.toLowerCase().trim();
      }

      authPromise = new Promise((resolve, reject) => {
        const authenticationData = {
          Username: username,
          Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const poolData = {
          UserPoolId: amazonConfig.userPoolId,
          ClientId: amazonConfig.appClientId,
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
          Username: username,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        return cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: result => {
            runInAction(() => {
              this.error = '';
              resolve(result);
            });
          },
          onFailure: err => {
            runInAction(() => {
              this.isLoading = false;
              this.error = err;
              reject(err);
            });
          },
        });
      });
    } else {
      let socialPlatformProvider;
      if (provider === 'linkedin') {
        // socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
      }
      if (socialPlatformProvider == null) {
        // return throwError({ error: { code: 'DEAL-UI0001', message: 'Invalid provider', args: [socialPlatformProvider] } })
      } else {
        // authPromise = from(this.socialAuthService.signIn(socialPlatformProvider));
      }
    }

    return authPromise.then(meData => {
      let token = '';
      token = meData.getIdToken().getJwtToken();

      return UserApi.getToken(token)
        .then(resp => {
          runInAction(() =>
            this.storeTokens(resp.data).then(() => {
              this.getDetails();
              return meData;
            }),
          );
        })
        .catch(error => {
          console.log('error =', {error});
          runInAction(() => {
            this.isUserLogged = false;
            this.isLoading = false;
            this.error = error.response?.data?.message;
          });
        });
    });
  }

  @action
  storeTokens(data) {
    // String cognito;
    // String firebase;
    // String awsKey;
    // String awsSecret;
    // String awsSessionToken;
    // long expirationTime;
    this.authToken = data.cognito;
    const emails = this.getLoggedInEmails();
    emails[data.username] = true;
    LocalStore.setObject('dealroom_data', {
      loginemails: JSON.stringify(emails),
      auth_cognito_token: data.cognito,
      auth_fb_token: data.firebase,
    });
    return this.authFbUser();
  }

  @action
  refreshToken(token) {
    return UserApi.getToken(token, true)
      .then(data => {
        this.storeTokens(data.data).then(() => {
          runInAction(() => {
            this.isProfileLoading = false;
            this.user.loaded = true;
            this.isUserLogged = true;
            this.isLoading = false;
          });
          this.rootStore.eventStore.getEvents();
          window.DR?.dispatchUserChange(this.user.id, true);
        });
        return data;
      })
      .catch(() => {
        LocalStore.remove('dealroom_data');
        LocalStore.remove('auth_cognito_token');
        LocalStore.remove('viewedAttendees');
        window.DR?.dispatchUserChange(this.user?.id, false);
      });
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  getLoggedInEmails() {
    const emailStr = LocalStore.get('loginemails');
    let emails = {};
    if (emailStr) {
      try {
        emails = JSON.parse(emailStr);
      } catch (e) {
        this.rootStore.errorStore.addError(e);
      }
    }
    return emails;
  }

  @action
  authFbUser() {
    const token = LocalStore.getObject('dealroom_data').auth_fb_token;

    return new Promise((resolve, reject) => {
      if (this.lastActiveFirebaseKey !== token) {
        this.lastActiveFirebaseKey = token;
        localStorage.setItem('auth_firebase', token);

        const prm = firebase.auth().signInWithCustomToken(token);
        prm.then(
          firebaseUser => {
            resolve(firebaseUser);
          },
          err => {
            reject(err);
          },
        );
      } else {
        resolve();
      }
    });
  }

  @action
  logout() {
    LocalStore.remove('dealroom_data');
    LocalStore.remove('viewedAttendees');
    defaultCache.reset();
    window.location.pathname = 'login';
    Sentry.configureScope(scope => scope.setUser(null));
    window.DR?.dispatchUserChange(this.user?.id, false);
    sendMessageToApp({
      action: AppActionType.LOGOUT,
    });
    /*
    this.isUserLogged = false;
    this.serviceCache.destroyAll();
    this.rootStore.eventStore.clean();
    this.rootStore.eventStore.cleanActiveEvents();
    this.rootStore.invitationStore.clean();
    this.rootStore.sessionsStore.clean();
    this.rootStore.sessionsStore.cleanAfterLogout();
    this.rootStore.activityStore.clean();
    this.rootStore.chatStore.clean();
    this.rootStore.exhibitorStore.clean();
    */
  }

  @action.bound
  signup(provider, params) {
    this.isLoading = true;
    const user = params;

    if (provider === 'cognito') {
      //
    } else {
      let socialPlatformProvider;
      if (provider === 'linkedin') {
        // socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
      }
      if (socialPlatformProvider == null) {
        throw new Error({
          error: {
            code: 'DEAL-UI0001',
            message: 'Invalid provider',
            args: [socialPlatformProvider],
          },
        });
      }
    }

    if (provider === 'linkedin') {
      user.accessToken = user.code;
      user.domain = `${window.location.protocol}//${window.location.host}`;
    }
    user.provider = provider;

    return UserApi.signup(user)
      .then(meData => {
        runInAction(() => {
          this.isLoading = false;
        });
        return this.storeTokens(meData.data.token).then(() => {
          this.getDetails();
          return meData;
        });
      })
      .catch(err => {
        runInAction(() => {
          this.isLoading = false;
          if (err) {
            this.error = err.response?.data?.message;
            this.errorInstance = err.response?.data;
          }
        });
      });
  }

  @action.bound
  deleteAccount(executorUserId) {
    return this.commandExecutor.execute(
      executorUserId,
      UserApi.deleteAccount()
        .then(resp => {
          this.logout();
          return Promise.resolve(resp.data);
        })
        .catch(error => Promise.reject(error.response.data)),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getCognitoUser(username) {
    const name = username.toLowerCase().trim();
    const poolData = {
      UserPoolId: amazonConfig.userPoolId,
      ClientId: amazonConfig.appClientId,
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: name,
      Pool: userPool,
    };
    return new CognitoUser(userData);
  }

  @action
  forgotPassword(username) {
    const cognitoUser = this.getCognitoUser(username);
    this.isLoading = true;

    cognitoUser.forgotPassword({
      onSuccess: () => {
        runInAction(() => {
          this.isLoading = false;
          this.resetPassword = true;
        });
      },
      onFailure: err => {
        runInAction(() => {
          this.isLoading = false;
          this.error = err.code;
        });
      },
      inputVerificationCode: () => {
        runInAction(() => {
          this.isLoading = false;
          this.resetPassword = true;
        });
      },
    });
  }

  @action.bound
  updateUserDetails(executorUserId, userDetails, reloadPage = false) {
    return this.commandExecutor.execute(
      executorUserId,
      UserApi.updateUserDetails(userDetails)
        .then(resp => {
          // TODO: Hotfix, because the backend does not immediately update the participant's data
          if (reloadPage) {
            window.location.reload();
            return Promise.resolve(resp.data);
          }

          runInAction(() => {
            this.user = resp.data;
            this.user.phone = resp.data.channels.sms;
          });
          this.rootStore.agendaStore.data.updateMeetingsFromAgenda();
          return Promise.resolve(resp.data);
        })
        .catch(error => Promise.reject(error.response?.data)),
    );
  }

  @action.bound
  getConnectedEmails() {
    return UserApi.getConnectedEmails()
      .then(resp => {
        runInAction(() => {
          this.connectedEmails = resp.data;
        });
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data));
  }

  @action.bound
  connectNewEmail(email) {
    return UserApi.connectNewEmail(email)
      .then(resp => {
        runInAction(() => {
          this.newEmail = email;
        });
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data))
      .finally(() => {
        runInAction(() => {
          this.isSendToAddNewEmail = true;
        });
      });
  }

  @action
  deleteEmail(email) {
    return UserApi.deleteEmail(email)
      .then(resp => {
        runInAction(() => {
          const arrayNumber = this.connectedEmails.indexOf(email);
          this.connectedEmails.splice(arrayNumber, 1);
        });
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data));
  }

  @action
  updateUsername(email) {
    return UserApi.updateUsername(email)
      .then(resp => {
        runInAction(() => {
          this.user.username = email;
        });
        return Promise.resolve(resp);
      })
      .catch(error => Promise.reject(error));
  }

  @action
  verifyCodeForAddingEmail(code) {
    return UserApi.verifyCodeForAddingEmail(code)
      .then(resp => {
        runInAction(() => {
          this.isSendToAddNewEmail = false;
        });
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data));
  }

  @action
  confirmConnectNewEmail(code, email) {
    this.newEmail = email;
    return this.verifyCodeForAddingEmail(code);
  }

  @action
  changeEmail() {
    this.isSendToAddNewEmail = false;
  }

  @action.bound
  // eslint-disable-next-line class-methods-use-this
  updatePassword(username, oldPassword, newPassword) {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: username,
        Password: oldPassword,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const poolData = {
        UserPoolId: amazonConfig.userPoolId,
        ClientId: amazonConfig.appClientId,
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
            runInAction(() => {
              this.isLoading = false;
              if (err) {
                return reject(err);
              }
              return resolve(res);
            });
          });
        },
        onFailure: err => {
          runInAction(() => {
            this.isLoading = false;
            reject(err);
          });
        },
      });
    });
  }

  @action.bound
  passwordReset(username, confirmationCode, newPassword) {
    let forgotPasswordPromise = '';
    this.isLoading = true;

    forgotPasswordPromise = new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);
      return cognitoUser.confirmPassword(confirmationCode, newPassword, {
        onSuccess: result => {
          runInAction(() => {
            this.isLoading = false;
            this.error = '';
            resolve(result);
          });
        },
        onFailure: err => {
          runInAction(() => {
            this.isLoading = false;
            this.error = err.message;
            reject(err);
          });
        },
      });
    });

    return forgotPasswordPromise.then(data => {
      console.log(data);
    });
  }

  @action.bound
  updateNotificationSettings(executorUserId, notificationSettings) {
    return this.commandExecutor.execute(
      executorUserId,
      UserApi.updateNotificationSettings(notificationSettings)
        .then(resp => {
          this.setUserDetails(resp.data);
          return Promise.resolve(resp.data);
        })
        .catch(error => Promise.reject(error.response.data)),
    );
  }

  @action
  cleanError() {
    this.error = '';
  }

  @action
  setQueryUser(eventDetails, attendeeDetails) {
    return new Promise(resolve => {
      const parts = [];
      if (attendeeDetails.categories !== null) {
        const userCategoriesMap = {};
        for (const k in attendeeDetails.categories) {
          if (Object.prototype.hasOwnProperty.call(attendeeDetails.categories, k)) {
            const catEntry = attendeeDetails.categories[k];
            userCategoriesMap[catEntry.categoryId] = catEntry;
          }
        }

        for (const categoryId in userCategoriesMap) {
          if (Object.prototype.hasOwnProperty.call(userCategoriesMap, categoryId)) {
            const catEntry = userCategoriesMap[categoryId];

            if (catEntry.selectedCategoryIds) {
              parts.push(
                `(categories.categoryId.exact:"${categoryId}" AND (categories.selectedCategoryIds.exact:("${catEntry.selectedCategoryIds.join(
                  '" OR "',
                )}")))`,
              );
              const matchingCategories = findMatchingCategories(eventDetails.categories, categoryId);
              for (const matchingCtIndex in matchingCategories) {
                if (Object.prototype.hasOwnProperty.call(matchingCategories, matchingCtIndex)) {
                  const matchingCategory = matchingCategories[matchingCtIndex];
                  parts.push(
                    `(categories.categoryId.exact:"${
                      matchingCategory.categoryId
                    }" AND (categories.selectedCategoryIds.exact:("${catEntry.selectedCategoryIds.join(
                      '" OR "',
                    )}")))^10`,
                  );
                }
              }
            }
          }
        }
      }

      // parts.push('city:'+currentParticipant.city+'^30')

      let order = 1;
      if (eventDetails.participantTypes) {
        const typeObject = eventDetails.participantTypes[attendeeDetails.typeId];
        if (typeObject) {
          attendeeDetails.participantType = typeObject.texts[eventDetails.defaultLanguage];
          if (attendeeDetails.participantType === null) {
            // eslint-disable-next-line prefer-destructuring
            attendeeDetails.participantType = Object.values(typeObject.texts)[0];
          }
          attendeeDetails.participantTypeConf = typeObject;
          if (typeObject.priority) {
            for (let i = 0; i < typeObject.priority.length; i++) {
              parts.push(
                `typeId.exact:${typeObject.priority[i]}^${Math.ceil(Math.pow(2, typeObject.priority.length - i))}`,
              );
              order++;
            }
          }
        }
      }
      parts.push('state:active^' + Math.pow(2, order + 1));
      parts.push('boothOwner:true^' + Math.pow(2, order + 2));
      // parts.push('state:active^30');
      // parts.push('boothOwner:true^5');
      this.user.query = parts.join(' OR ');
      return resolve(this.user.query);
    });
  }

  @action
  setParticipantBooth(participantBooth) {
    this.myParticipantBooth = participantBooth;
  }

  @action
  cleanParticipantBooth() {
    this.myParticipantBooth = null;
  }

  setSkippedTimezone(currentUserTimezone, localTimezone) {
    return firebase
      .database()
      .ref(`ui/${this.user.id}/timezoneCheck`)
      .set({userTimezone: currentUserTimezone, localTimezone: localTimezone})
      .then(el => el)
      .catch(error => {
        console.log('error', error);
      });
  }

  @action
  tokenLogin(token, isUpdate = false) {
    return UserApi.getToken(token, isUpdate)
      .then(async data => {
        this.storeTokens(data.data)
          .then(() => {
            runInAction(() => {
              this.isProfileLoading = false;
              this.user.loaded = true;
              this.isUserLogged = true;
              this.isLoading = false;
            });
            this.rootStore.eventStore.getEvents();
            window.DR?.dispatchUserChange(this.user.id, true);
          })
          .catch(e => {
            console.log('error', e);
            window.location.href = '/';
          });

        const details = await this.rootStore.eventStore.loadDomainDetails();
        if (details) {
          window.location.href = `event/${details.id}/token`;
        } else {
          window.location.href = '/';
        }
        return data;
      })
      .catch(() => {
        sendMessageToApp({
          action: AppActionType.LOGOUT,
        });
        LocalStore.remove('dealroom_data');
        LocalStore.remove('auth_cognito_token');
        LocalStore.remove('viewedAttendees');
        window.DR?.dispatchUserChange(this.user?.id, false);
        window.location.href = '/';
      });
  }
}
