import React from 'react';
import {observer} from 'mobx-react-lite';
import {AppContext} from '../../store';
import {useLocation} from 'react-router-dom';

export const TokenLogin = observer(() => {
  const {userStore} = React.useContext(AppContext);
  const queryParams = useQuery();
  React.useEffect(() => {
    userStore.tokenLogin(queryParams.get('token'), queryParams.get('internal'));
  }, []);

  return <div />;
});

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
