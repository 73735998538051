import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import addMainClasses from '../utils/addMainClasses';
import {AppContext, stores} from '../store';
import NotificationsPopup from '../components/notifications-alert/NotificationsPopup';
import Modals from '../components/modals';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import MainLayout from './MainLayout';
import PreLoginLayout from './PreLoginLayout';
import StaticPageLayout from './StaticPageLayout';
import ConfirmEmail from '../pages/ConfirmEmail';
import PreRegistrationFormPage from '../pages/PreRegistrationFormPage';
import Localizer from '../providers/Localizer';
import ErrorBoundary from '../providers/ErrorBoundary';
import CookieConsent from '../components/shared/CookieConsent';
import TimeService from '../services/core/time-service';
import searchParse from '../utils/searchParse';
import {TokenLogin} from '../pages/TokenLogin/TokenLogin';

const AppRoot = observer(() => {
  const {languageStore, userStore, modalStore, uiStore} = React.useContext(AppContext);
  const {isUserLogged, isProfileLoading, user} = userStore;
  const preloader = document.querySelector('.site-preloader');

  // site preloader
  if (!userStore.isLoading && preloader) {
    preloader.classList.add('site-preloader__fade');
    setTimeout(() => {
      preloader.parentNode && preloader.parentNode.removeChild(preloader);
    }, 500);
  }

  React.useEffect(() => {
    if (isUserLogged && !isProfileLoading) {
      const timezone = TimeService.getMyLocalTimezone();
      if (!user.timezone) {
        userStore
          .updateUserDetails(user.id, {
            action: 'profile',
            channels: {sms: user.phoneNumber || ''},
            name: user.name,
            surname: user.surname,
            timezone: timezone,
          })
          .then(() => {
            userStore.setSkippedTimezone(timezone, TimeService.getMyLocalTimezone());
            modalStore.open('timezoneModal', {registeredNewUser: true});
          });
      } else if (
        user.timezone !== timezone &&
        user.uiSettings != null &&
        (user.uiSettings.timezoneCheck == null ||
          user.uiSettings.timezoneCheck.userTimezone !== user.timezone ||
          user.uiSettings.timezoneCheck.localTimezone !== timezone)
      ) {
        modalStore.open('timezoneModal');
      }
    }
  }, [isUserLogged, isProfileLoading, user]);

  // Only for native app
  const {appNative} = searchParse(window.location.search);
  if (appNative && !uiStore.appNative) {
    uiStore.setAppNative();
  }

  return (
    <Localizer locale={languageStore.language}>
      <HelmetProvider>
        <Router>
          <ErrorBoundary>
            {addMainClasses(stores)}
            <NotificationsPopup />
            <Modals />
            <Switch>
              <PublicRoute exact path="/token-login" component={TokenLogin} />
              <PublicRoute
                exact
                path="/:page(login|registration|preregister|forgot-password|forgot-password-confirm)"
                component={PreLoginLayout}
              />
              <PublicRoute exact path="/event-preregister" component={PreRegistrationFormPage} />
              <Route exact path="/confirmEmail" component={ConfirmEmail} />
              <Route exact path="/:page(terms|privacy-notice|faq|cookies)" component={StaticPageLayout} />
              <PrivateRoute component={() => <MainLayout />} />
            </Switch>
            <CookieConsent />
          </ErrorBoundary>
        </Router>
      </HelmetProvider>
    </Localizer>
  );
});

export default AppRoot;
